@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body,
html {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Lato", sans-serif;
    background: linear-gradient(rgb(32, 39, 56) 0%, rgb(7, 8, 22) 100%);
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' strokeWidth='2' strokeLinecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

:root {
    --genie-colors-accentFailure: var(--rgw6ez13);
    --genie-colors-accentFailureSoft: rgba(253, 118, 107, 0.12);
    --genie-colors-accentAction: var(--rgw6ezy);
    --genie-colors-accentActionSoft: rgba(76, 130, 251, 0.24);
    --genie-colors-accentSuccess: var(--rgw6ez16);
    --genie-colors-explicitWhite: #ffffff;
    --genie-colors-green: var(--rgw6ez16);
    --genie-colors-gold: var(--rgw6ez14);
    --genie-colors-violet: var(--rgw6ez19);
    --genie-colors-backgroundFloating: 0000000c;
    --genie-colors-backgroundInteractive: var(--rgw6ez1o);
    --genie-colors-backgroundModule: var(--rgw6ez1q);
    --genie-colors-backgroundOutline: var(--rgw6eza);
    --genie-colors-backgroundSurface: var(--rgw6ez1s);
    --genie-colors-backgroundBackdrop: var(--rgw6ez1t);
    --genie-colors-modalBackdrop: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    --genie-colors-searchBackground: rgba(255, 255, 255, 0.07);
    --genie-colors-searchOutline: rgba(255, 255, 255, 0.07);
    --genie-colors-stateOverlayHover: rgba(153, 161, 189, 0.08);
    --genie-colors-textPrimary: #ffffff;
    --genie-colors-textSecondary: var(--rgw6ez1g);
    --genie-colors-textTertiary: var(--rgw6ez1k);
    --genie-colors-dropShadow: 0px 4px 16px rgba(76, 130, 251, 0.4);
    --genie-shadows-menu: 0px 10px 30px rgba(0, 0, 0, 0.1);
    --genie-shadows-elevation: 0px 4px 16px rgba(70, 115, 250, 0.4);
    --genie-shadows-tooltip: 0px 4px 16px rgba(255, 255, 255, 0.2);
    --genie-shadows-deep: 12px 16px 24px rgba(0, 0, 0, 0.24), 12px 8px 12px rgba(0, 0, 0, 0.24), 4px 4px 8px rgba(0, 0, 0, 0.32);
    --genie-shadows-shallow: 4px 4px 10px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.12), 1px 2px 2px rgba(0, 0, 0, 0.12);
    --genie-opacity-hover: 0.6;
    --genie-opacity-pressed: 0.4;
}

.d-box-align {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    z-index: 1000;
}

/* navbar css */

.nav-d {
    padding: 20px 12px;
    width: 100%;
    height: 72px;
    z-index: 2;
}
.border-box-s {
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    vertical-align: initial;
}

.flex-start-justify {
    justify-content: flex-start;
}
.d-flex {
    display: flex;
}

.flex-shrink-2 {
    flex-shrink: 2px;
}

.coin-block {
    display: inline-block;
    height: inherit;
}

.line-height-color {
    line-height: var(--rgw6ez2y);
}

.arrow-swap {
    background: unset;
    border: unset;
    width: 100%;
}
.w-self {
    width: 100vw;
}
.bp-12 {
    padding-bottom: 12px;
}

.pl-16 {
    padding-left: 16px;
}

.pt-12 {
    padding-top: 12px;
}

.flex-shrink-1 {
    flex-shrink: 1px;
}
.flex-shrink-2 {
    flex-shrink: 2px;
}
.flex-1-s {
    flex: 1;
}

.d-flex {
    display: flex;
}
.flex-start-justify {
    justify-content: flex-start;
}
.justify-self {
    justify-content: flex-end;
}
.arf-col {
    color: var(--rgw6ezk);
}
.input-d-bg {
    background: var(--rgw6ezw);
}
.border-col-sw {
    border-color: var(--rgw6ezh);
}

.cursor-pointer {
    cursor: pointer;
}
.border-solid-s {
    border-style: solid;
}

.search-input {
    gap: 12px;
    border-radius: var(--rgw6ez25);
    background-color: var(--rgw6ezg);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
}

.input-d {
    color: white;
    border: var(--rgw6ez20);
}
.border-w-1 {
    border-width: 1px;
}

@media screen and (min-width: 1280px) {
    .responsive-s {
        width: 480px;
    }
}
@media screen and (min-width: 1024px) {
    .responsive-s {
        width: 360px;
    }
}

@media screen and (min-width: 768px) {
    .responsive-s {
        width: 330px;
    }
}

@media screen and (min-width: 640px) {
    .filter-d {
        width: 360px;
    }
}

:root {
    --reach-dialog: 1;
}

:root {
    --rgw6ez0: var(--genie-colors-accentFailure);
    --rgw6ez1: var(--genie-colors-accentFailureSoft);
    --rgw6ez2: var(--genie-colors-accentAction);
    --rgw6ez3: var(--genie-colors-accentActionSoft);
    --rgw6ez4: var(--genie-colors-accentSuccess);
    --rgw6ez5: var(--genie-colors-explicitWhite);
    --rgw6ez6: var(--genie-colors-gold);
    --rgw6ez7: #209853;
    --rgw6ez8: var(--genie-colors-violet);
    --rgw6ez9: var(--genie-colors-backgroundFloating);
    --rgw6eza: var(--genie-colors-backgroundInteractive);
    --rgw6ezb: var(--genie-colors-backgroundModule);
    --rgw6ezc: var(--genie-colors-backgroundOutline);
    --rgw6ezd: var(--genie-colors-backgroundSurface);
    --rgw6eze: var(--genie-colors-backgroundBackdrop);
    --rgw6ezf: var(--genie-colors-modalBackdrop);
    --rgw6ezg: var(--genie-colors-searchBackground);
    --rgw6ezh: var(--genie-colors-searchOutline);
    --rgw6ezi: var(--genie-colors-stateOverlayHover);
    --rgw6ezj: var(--genie-colors-textPrimary);
    --rgw6ezk: var(--genie-colors-textSecondary);
    --rgw6ezl: var(--genie-colors-textTertiary);
    --rgw6ezm: var(--genie-colors-dropShadow);
    --rgw6ezn: linear-gradient(270deg, #d1d5db, #f6f6f6);
    --rgw6ezo: #24272e;
    --rgw6ezp: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    --rgw6ezq: #fa2c38;
    --rgw6ezr: #000;
    --rgw6ezs: #f5f5f5;
    --rgw6ezt: #cbcedc;
    --rgw6ezu: #7f7f7f;
    --rgw6ezv: transparent;
    --rgw6ezw: none;
    --rgw6ezx: #fff;
    --rgw6ezy: #4c82fb;
    --rgw6ezz: #adbcff30;
    --rgw6ez10: #fb118e;
    --rgw6ez11: #530f10;
    --rgw6ez12: #fa2c38;
    --rgw6ez13: #fd766b;
    --rgw6ez14: #eeb317;
    --rgw6ez15: #b17900;
    --rgw6ez16: #5cfe9d;
    --rgw6ez17: #40b66b;
    --rgw6ez18: #1a9550;
    --rgw6ez19: #bdb8fa;
    --rgw6ez1a: #7a7beb;
    --rgw6ez1b: #f5f6fc;
    --rgw6ez1c: #e8ecfb;
    --rgw6ez1d: #d2d9ee;
    --rgw6ez1e: #b8c0dc;
    --rgw6ez1f: #a6afca;
    --rgw6ez1g: #98a1c0;
    --rgw6ez1h: #888fab;
    --rgw6ez1i: #7780a0;
    --rgw6ez1j: #6b7594;
    --rgw6ez1k: #5d6785;
    --rgw6ez1l: #505a78;
    --rgw6ez1m: #404a67;
    --rgw6ez1n: #333d59;
    --rgw6ez1o: #293249;
    --rgw6ez1p: #1b2236;
    --rgw6ez1q: #131a2a;
    --rgw6ez1r: #0e1524;
    --rgw6ez1s: #0d111c;
    --rgw6ez1t: #080b11;
    --rgw6ez1u: hsla(0, 0%, 100%, 0.12);
    --rgw6ez1v: rgba(153, 161, 189, 0.24);
    --rgw6ez1w: #99a1bd14;
    --rgw6ez1x: #4c82fb3d;
    --rgw6ez1y: #4c82fb;
    --rgw6ez1z: 1.5px solid rgba(0, 0, 0, 0.1);
    --rgw6ez20: none;
    --rgw6ez21: 0px;
    --rgw6ez22: 4px;
    --rgw6ez23: 8px;
    --rgw6ez24: 10px;
    --rgw6ez25: 12px;
    --rgw6ez26: 14px;
    --rgw6ez27: 16px;
    --rgw6ez28: 20px;
    --rgw6ez29: 30px;
    --rgw6ez2a: 40px;
    --rgw6ez2b: 100px;
    --rgw6ez2c: 16px;
    --rgw6ez2d: 24px;
    --rgw6ez2e: 9999px;
    --rgw6ez2f: 0;
    --rgw6ez2g: 10px;
    --rgw6ez2h: 12px;
    --rgw6ez2i: 14px;
    --rgw6ez2j: 16px;
    --rgw6ez2k: 20px;
    --rgw6ez2l: 24px;
    --rgw6ez2m: 28px;
    --rgw6ez2n: 34px;
    --rgw6ez2o: 36px;
    --rgw6ez2p: 40px;
    --rgw6ez2q: 48px;
    --rgw6ez2r: 60px;
    --rgw6ez2s: 96px;
    --rgw6ez2t: 1px;
    --rgw6ez2u: 12px;
    --rgw6ez2v: 14px;
    --rgw6ez2w: 16px;
    --rgw6ez2x: 20px;
    --rgw6ez2y: 24px;
    --rgw6ez2z: 28px;
    --rgw6ez30: 36px;
    --rgw6ez31: 44px;
    --rgw6ez32: auto;
    --rgw6ez33: 400;
    --rgw6ez34: 500;
    --rgw6ez35: 600;
    --rgw6ez36: 700;
    --rgw6ez37: 900;
    --rgw6ez38: 125ms;
    --rgw6ez39: 250ms;
    --rgw6ez3a: 500ms;
    --rgw6ez3b: Inter, sans-serif;
    --rgw6ez3c: Adieu, sans-serif;
}

.self-align {
    align-items: flex-start;
}

.align-item-center {
    align-items: center;
}

.line-height-color {
    line-height: 24px;
    line-height: var(--rgw6ez2y);
}

.pr-16 {
    padding-right: 16px;
}

.flex-shrink-2 {
    flex-shrink: 2px;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.flex-start-justify {
    justify-content: flex-start;
}

.search-input-color::-webkit-input-placeholder {
    color: var(--genie-colors-textSecondary);
    color: var(--rgw6ezk);
}

.input-d-bg {
    background: none;
    background: var(--rgw6ezw);
}

.cursor-pointer {
    cursor: pointer;
}

.input-d {
    border: none;
    border: var(--rgw6ez20);
}

input:focus-visible {
    outline: unset;
}

.tal-s:before {
    /* background: linear-gradient(91.46deg, #4673fa, #9646fa 100.13%) border-box; */
    border: 1px solid transparent;
    border-radius: inherit;
    content: "";
    inset: -1px;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    position: absolute;
}

.opacity-st:before {
    opacity: 0;
    transition: opacity 0.25s ease-out;
}

.opacity-st:hover:before {
    opacity: 1;
}

.filter-d {
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
}

@media screen and (min-width: 640px) {
    .filter-d {
        width: 360px;
    }
}

@media screen and (min-width: 768px) {
    .responsive-s {
        width: 330px;
    }
}

@media screen and (min-width: 1024px) {
    .responsive-s {
        width: 360px;
    }
}

@media screen and (min-width: 1280px) {
    .responsive-s {
        width: 480px;
    }
}

@media screen and (min-width: 1536px) {
    .responsive-s {
        width: 520px;
    }
}

@media screen and (min-width: 1920px) {
    .responsive-s {
        width: 640px;
    }
}

.nav-an {
    line-height: 24px;
    text-decoration: none;
    font-weight: 500;
    font-weight: var(--rgw6ez34);
    padding: 10px 15px;
    color: #98a1c0;
}
.nav-an.active {
    color: #ffffff;
}

.nav-an:hover {
    background: var(--rgw6ez1w);
    border-radius: 10px;
    color: white;
    padding: 10px 15px;
}

.connect-div {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgba(76, 130, 251, 0.24);
    border-radius: 9999px;
    border: none;
    padding: 0px;
    height: 40px;
    color: rgb(76, 130, 251);
    transition: color 125ms ease-in 0s;
}

.connet-btn {
    background-color: transparent;
    border: none;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 12px;
    color: inherit;
}

/* Card Css */
.card-st {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    /* padding: 72px 0px 5rem; */
    -webkit-box-align: center;
    align-items: center;
    flex: 1 1 0%;
}
.card-d {
    padding: 68px 8px 0px;
    max-width: 480px;
    width: 100%;
}
.card-d-bg {
    position: relative;
    background: rgb(13, 17, 28);
    border-radius: 16px;
    border: 1px solid rgba(152, 161, 192, 0.24);
    padding: 12px 8px 8px;
    z-index: 1;
    transition: transform 250ms ease 0s;
}
.ma-color {
    margin-bottom: 10px;
    color: rgb(152, 161, 192);
}
.space-s-between {
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
}
.flex-adjust-d {
    width: 100%;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
}
.border-boxing {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
}

.width-fit-d {
    width: fit-content;
}
.pad-link {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.s-color {
    color: rgb(255, 255, 255);
}

.coins-list {
    position: absolute;
    top: 36px;
}

.curr-box-btn {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    appearance: none;
    display: inline-block;
    text-align: center;
    line-height: inherit;
    text-decoration: none;
    font-size: inherit;
    padding: 8px 16px;
    color: white;
    border: 0px;
    border-radius: 4px;
}

.border-unset-d {
    border: unset;
}

.bg-unset {
    background: unset !important;
}

.svg-pad {
    padding: 6px 12px;
    border-radius: 16px;
}

.svg-d {
    height: 20px;
    width: 20px;
}
.svg-d > * {
    stroke: rgb(152, 161, 192);
}

.swap-card {
    position: relative;
    background-color: rgb(19, 26, 42);
    border-radius: 12px;
    padding: 16px;
    color: rgb(152, 161, 192);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}
.swap-card::before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: "";
    border: 1px solid rgb(19, 26, 42);
}
.flex-wrap-d {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    border-radius: 20px;
    z-index: 1;
    width: initial;
    transition: height 1s ease 0s;
    will-change: height;
}
.input-contain {
    min-height: 44px;
    border-radius: 20px;
    width: initial;
}
.flex-ne {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.input-swap-d {
    color: rgb(255, 255, 255);
    width: 0px;
    position: relative;
    font-weight: 400;
    outline: none;
    border: none;
    flex: 1 1 auto;
    background-color: transparent;
    font-size: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px;
    appearance: textfield;
    text-align: right;
}
.input-d-swp {
    /* width: 100%; */
    background: unset;
    border: unset;
    color: white;
    filter: none;
    opacity: 1;
    transition: opacity 0.2s ease-in-out 0s;
    text-align: left;
    font-size: 36px;
    line-height: 44px;
    font-variant: small-caps;
}

.bg-border {
    background: unset;
    border: unset;
}

.currency-s {
    background-color: rgb(19, 26, 42);
    color: rgb(152, 161, 192);
    font-size: 16px;
    font-weight: 500;
}

.curr-btn {
    padding: 16px;
    width: 100%;
    font-weight: 500;
    text-align: center;
    border-radius: 20px;
    outline: none;
    border: 1px solid transparent;
    color: rgb(255, 255, 255);
    text-decoration: none;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    will-change: transform;
    transition: transform 450ms ease 0s;
    transform: perspective(1px) translateZ(0px);
}

.currency-img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: radial-gradient(white 60%, rgba(255, 255, 255, 0) calc(70% + 1px));
    box-shadow: white 0px 0px 1px;
}
.currency-text {
    margin: 0px 0.25rem;
    font-size: 20px;
    font-weight: 600;
}
.curr-svg {
    margin: 0px 0.25rem 0px 8px;
    height: 35%;
}

.curr-svg path {
    stroke: rgb(255, 255, 255);
    strokeWidth: 2px;
}
.flex-s-d-b {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.currency-btn-s::before {
    background-size: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    content: "";
}

.currency-btn-s {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(41, 50, 73);
    opacity: 1;
    box-shadow: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: unset;
    border-radius: 16px;
    outline: none;
    user-select: none;
    border: none;
    font-size: 24px;
    font-weight: 400;
    width: initial;
    padding: 4px 8px 4px 4px;
    gap: 8px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-left: 12px;
    visibility: visible;
}

.cldnjs {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(76, 130, 251);
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 6px 10px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: unset;
    border-radius: 16px;
    outline: none;
    user-select: none;
    border: none;
    font-size: 24px;
    font-weight: 400;
    width: initial;
    padding: 6px 6px 6px 8px;
    gap: 8px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-left: 12px;
    visibility: visible;
}
.cldnjs::before {
    background-size: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    content: "";
}

.arrow-div {
    border-radius: 12px;
    height: 40px;
    width: 40px;
    position: relative;
    margin: -18px auto;
    background-color: rgb(41, 50, 73);
    border: 4px solid rgb(13, 17, 28);
    z-index: 2;
}
.flex-new-d {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.border-s-white {
    border: 1px solid white;
}

.justify-space-between {
    justify-content: space-between;
}

.accordion-itm {
    background-color: unset;
    border: unset;
}

.accordion-st {
    background-color: unset;
    border: 1px solid rgba(152, 161, 192, 0.24);
    border-radius: 12px;
}

.accord-btn {
    background-color: unset;
    border: unset !important    ;
}

.btn-connect {
    background-color: rgba(76, 130, 251, 0.24);
    color: rgb(76, 130, 251);
    font-size: 20px;
    font-weight: 600;
}

.curr-btn > * {
    user-select: none;
}

.swap-card {
    position: relative;
    background-color: rgb(19, 26, 42);
    border-radius: 12px;
    padding: 16px;
    color: rgb(152, 161, 192);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.input-div-s {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 4px;
}

.amt-d {
    -webkit-box-pack: end;
    justify-content: flex-end;
    min-height: 20px;
    padding: 8px 0px 0px;
}

.amount-div {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(152, 161, 192);
    font-size: 0.75rem;
    line-height: 1rem;
}

/* Dropdown */
.select-des {
    min-width: 20.125rem;
    background-color: rgb(13, 17, 28);
    border: 1px solid rgba(152, 161, 192, 0.24);
    box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px;
    border-radius: 12px;
    position: absolute;
    top: 100%;
    margin-top: 10px;
    right: 0px;
    z-index: 100;
    color: rgb(255, 255, 255);
    user-select: none;
    gap: 16px;
    padding: 1rem;
}
.grid-align {
    display: grid;
    grid-auto-rows: auto;
}

.flex-du {
    width: 100%;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 8px;
}

button {
    user-select: none;
}

.box-fixing-d {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 400;
    font-size: 16px;
}

.div-s-d {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 400;
    font-size: 12px;
}

.grey-color-d {
    color: rgb(152, 161, 192);
}

.gap-d {
    gap: 1.5px;
    border-radius: 12px;
    overflow: hidden;
}

.flex-column-start-d {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.cursor-pointer {
    cursor: pointer;
}

.hr-ds {
    width: 100%;
    height: 1px;
    border-width: 0px;
    margin: 0px;
    background-color: rgba(152, 161, 192, 0.24);
}

.bd-self-j {
    background-color: rgb(19, 26, 42);
    padding: 12px 16px;
}

.self-new {
    border: 1px solid rgba(152, 161, 192, 0.24);
    border-radius: 0.375rem;
}

.flex-origin {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 12px;
}

.flex-start-d {
    width: 100%;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 12px;
}

.flex-input-j {
    padding: 8px 16px;
    border-radius: 12px;
    width: auto;
    flex: 1 1 0%;
    border: 1px solid rgba(152, 161, 192, 0.24);
}

.flex-input-j input {
    color: rgb(255, 255, 255);
}

.djPkeO {
    width: 100%;
    display: flex;
    flex: 1 1 0%;
    font-size: 16px;
    border: 0px;
    outline: none;
    background: transparent;
    text-align: right;
}

.dropdown-toggle::after {
    display: none !important;
}

/* Modal css  */

.modal-st {
    background-color: rgb(13, 17, 28);
    width: 100%;
    overflow: hidden;
    flex: 1 1 0%;
    position: relative;
    border-radius: 20px;
}

.s-pad {
    padding: 20px;
}

.grid-mod {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 16px;
}

.currency-div-2 {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 500;
    font-size: 16px;
    color: white;
}

.ealZYI {
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.add-input {
    background: url("../public/img/coin.png") 12px center / 20px 20px no-repeat scroll rgb(19, 26, 42);
    position: relative;
    display: flex;
    padding: 16px 16px 16px 40px;
    height: 40px;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    outline: none;
    border-radius: 12px;
    color: rgb(255, 255, 255);
    border: 1px solid rgba(152, 161, 192, 0.24);
    appearance: none;
    font-size: 16px;
    transition: border 100ms ease 0s;
}

.grid-modal {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 12px;
}

.ar-mod {
    flex-wrap: wrap;
    margin: -4px;
}

.modal-s-ds {
    width: 100%;
    display: flex;
    padding: 0px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 4px;
}

.active-item {
    border: 1px solid rgb(76, 130, 251);
    border-radius: 16px;
    display: flex;
    padding: 6px 12px 6px 6px;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(76, 130, 251);
    background-color: rgba(76, 130, 251, 0.24);
}

.ar-mod > * {
    margin: 4px !important;
}

.mr-8 {
    margin-right: 8px;
}

.curr-tab {
    border: 1px solid rgba(152, 161, 192, 0.24);
    border-radius: 16px;
    display: flex;
    padding: 6px 12px 6px 6px;
    -webkit-box-align: center;
    align-items: center;
}

.currency-list-ne {
    flex: 1 1 0%;
    position: relative;
}

.modal-d {
    margin: auto;
    background-color: rgb(13, 17, 28);
    border: 1px solid rgba(152, 161, 192, 0.24);
    box-shadow: rgba(0, 0, 0, 0.24) 12px 16px 24px, rgba(0, 0, 0, 0.24) 12px 8px 12px, rgba(0, 0, 0, 0.32) 4px 4px 8px;
    padding: 0px;

    border-radius: 20px;
}

.line-d {
    width: 100%;
    height: 1px;
    background-color: rgba(152, 161, 192, 0.24);
}

.contain-cure {
    padding-right: 0.25rem;
}

.token-d {
    padding: 4px 20px;
    height: 56px;
    display: grid;
    grid-template-columns: auto minmax(auto, 1fr) auto minmax(0px, 72px);
    gap: 16px;
    pointer-events: none;
    opacity: 0.4;
}

.coin-img-d {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: radial-gradient(white 60%, rgba(255, 255, 255, 0) calc(70% + 1px));
    box-shadow: white 0px 0px 1px;
}

.coin-css {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
}

.coin-title {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.coin-dne {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 300;
    font-size: 12px;
}

.coin-short-title {
    color: rgb(93, 103, 133);
}

.self-align-j {
    height: 56px;
    width: 100%;
}

.opacity-1 {
    opacity: 1;
}

.coin-jj {
    padding: 4px 20px;
    height: 56px;
    display: grid;
    grid-template-columns: auto minmax(auto, 1fr) auto minmax(0px, 72px);
    gap: 16px;
    cursor: pointer;
    opacity: 1;
}

.height-auto {
    height: auto;
}

.scrollbar {
    overflow-y: scroll;
    position: relative;
    height: 300px;
    width: 100%;
    overflow: auto;
    will-change: transform;
    direction: ltr;
}

#style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgb(13, 17, 28);
}

#style-2::-webkit-scrollbar {
    width: 7px;
    background-color: rgb(13, 17, 28);
}

#style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(19, 26, 42);
}

div.mm-dropdown {
    cursor: pointer;
    width: 100%;
    border-radius: 3px;
    font-weight: 500;
}

.accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button:not(.collapsed) {
    background-color: unset;
    box-shadow: unset;
}

.text-grey {
    color: #a7abad;
}

div.mm-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 0;
}

.align-item-center {
    align-items: center;
}

div.mm-dropdown ul li,
div.mm-dropdown div.textfirst {
    padding: 0;
    color: #333;
    padding: 5px 15px;
}

div.mm-dropdown ul li:last-child {
    border-bottom: 0;
}

div.mm-dropdown ul li {
    cursor: pointer;
}

div.mm-dropdown ul li img {
    width: 20px;
    height: 20px;
}

.d-none{
    display: none;
}

.d-block{
    display: block;
}